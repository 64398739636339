import { filter } from '@chakra-ui/react';

import { CompetitionSlotsEnum } from '@/enums/competitionSlots.enum';
import { ConferenceAlignEnum } from '@/enums/conferenceAlign.enum';

import { MatchesInitialStateType } from '@/store/slices/matches.slice';

import colors from '@/theme/foundations/colors';

import {
  StoreConferenceType,
  OptionStatusType,
  UseTranslateDataType,
} from '@/types/custom';
import { ConferenceType, MatchSelectionType, MatchType } from '@/types/domain';

import { isExist } from '@/utils/common.util';

export const matchItemMt: Record<number, string> = {
  16: '0',
  8: '0',
  4: '44px',
  2: '131px',
  1: '304px',
  0: '0',
};
export const matchItemEvery2nMt: Record<number, string> = {
  16: '0',
  8: '0',
  4: '0',
  2: '0',
  1: '0',
  0: '0',
};
export const matchItemEvery2nMb: Record<number, string> = {
  16: '0',
  8: '0',
  4: '42px',
  2: '40px',
  1: '40px',
  0: '0',
};
export const matchItemMb: Record<number, string> = {
  16: '0',
  8: '0',
  4: '86px',
  2: '258px',
  1: '84px',
  0: '0',
};
export const connectorHeight: Record<number, string> = {
  16: '88px',
  8: '88px',
  4: '174px',
  2: '346px',
  1: '90px',
  0: '0',
};
export const connectorMt: Record<number, string> = {
  16: '48px',
  8: '48px',
  4: '0',
  2: '0',
  1: '0',
  0: '0',
};
export const connectorMb: Record<number, string> = {
  16: '0',
  8: '0',
  4: '170px',
  2: '148px',
  1: '0',
  0: '0',
};
export const connectorEvery2nMt: Record<number, string> = {
  16: '84px',
  8: '84px',
  4: '0',
  2: '0',
  1: '0',
  0: '0',
};
export const connectorEvery2nMb: Record<number, string> = {
  16: '50px',
  8: '50px',
  4: '0',
  2: '0',
  1: '0',
  0: '0',
};
export const connectorEvery1nMt: Record<number, string> = {
  16: '34px',
  8: '34px',
  4: '78px',
  2: '165px',
  1: '293px',
  0: '0',
};

export const roundTitleOffsetTop: Record<number, string> = {
  16: '0',
  8: '0',
  4: '5px',
  2: '85px',
  1: '255px',
  0: '0',
};

export const teamBgColorByOptionStatus: Record<OptionStatusType, string> = {
  none: colors.aolisBlue,
  selection: colors.brekYellow,
  correct: colors.sontarGreen,
  incorrect: colors.rydelRed,
  locked: colors.brekYellow,
  eliminated: colors.aolisBlue,
};

export const connectorBorderRadiusByAlign: Record<ConferenceAlignEnum, string> =
  {
    left: '0 8px 8px 0',
    right: '8px 0 0 8px',
    center: '0 8px 8px 0',
  };

export const connectorFloatByAlign: Record<ConferenceAlignEnum, string> = {
  left: 'left',
  right: 'right',
  center: 'left',
};

export const renderConnectorBorderStylesByAlign = (
  size: number,
  align: ConferenceAlignEnum,
) => {
  if (size <= 1) return { border: '0' };
  else {
    switch (align) {
      case ConferenceAlignEnum.center:
        return {
          borderTop: '2px solid',
          borderRight: '2px solid',
          borderBottom: '2px solid',
          borderLeft: '0',
          borderColor: 'imnerGrey',
        };
      case ConferenceAlignEnum.right:
        return {
          borderTop: '2px solid',
          borderRight: '0',
          borderBottom: '2px solid',
          borderLeft: '2px solid',
          borderColor: 'imnerGrey',
        };
      case ConferenceAlignEnum.left:
      default:
        return {
          borderTop: '2px solid',
          borderRight: '2px solid',
          borderBottom: '2px solid',
          borderLeft: '0',
          borderColor: 'imnerGrey',
        };
    }
  }
};

export const renderConnectorBorderPseudoAfterStylesByAlign = (
  size: number,
  align: ConferenceAlignEnum,
) => {
  if (size <= 1) return { left: '0' };
  else {
    switch (align) {
      case ConferenceAlignEnum.center:
        return {
          left: '12px',
        };
      case ConferenceAlignEnum.right:
        return {
          right: '12px',
        };
      case ConferenceAlignEnum.left:
      default:
        return {
          left: '12px',
        };
    }
  }
};

export const isAwayTeamInSelections = (
  team_id: number | undefined | null,
  match: MatchType | undefined,
) => team_id === match?.away_team_id;
export const isHomeTeamInSelections = (
  team_id: number | undefined | null,
  match: MatchType | undefined,
) => team_id === match?.home_team_id;

export const getOptionStatusByTeamId = ({
  team_id,
  isEnabled,
  match,
  foundSelection,
  foundMatchInInitialMatches,
}: {
  team_id: number | null;
  isEnabled: boolean;
  match: MatchType | undefined;
  foundSelection: Partial<MatchSelectionType> | undefined;
  foundMatchInInitialMatches: MatchType | undefined;
}): OptionStatusType => {
  const isAwayTeam = isAwayTeamInSelections(team_id, match);
  const isHomeTeam = isHomeTeamInSelections(team_id, match);
  const isAwayTeamWon = match?.winner_team_id === match?.away_team_id;
  const isHomeTeamWon = match?.winner_team_id === match?.home_team_id;
  const isAwayTeamEqualsToAwayInInitMatch =
    team_id === foundMatchInInitialMatches?.away_team_id;
  const isHomeTeamEqualsToHomeInInitMatch =
    team_id === foundMatchInInitialMatches?.home_team_id;

  if (isEnabled || match?.winner_team_id === null) {
    if (foundSelection) {
      return 'selection';
    } else {
      return 'none';
    }
  } else {
    if (foundSelection && foundMatchInInitialMatches) {
      // TODO
      // if (match?.status === MatchTypeEnum.LIVE) {
      //   return 'locked';
      // }
      if (isAwayTeam) {
        if (!isAwayTeamEqualsToAwayInInitMatch) return 'eliminated';
        else if (isAwayTeamWon) return 'correct';
        else return 'incorrect';
      } else if (isHomeTeam) {
        if (!isHomeTeamEqualsToHomeInInitMatch) return 'eliminated';
        if (isHomeTeamWon) return 'correct';
        else return 'incorrect';
      } else {
        return 'locked';
      }
    } else return 'none';
  }
};

export const createRoundsGridByConferences = ({
  matches,
  conferences,
}: {
  matches: MatchType[];
  conferences: ConferenceType[];
}): StoreConferenceType[] => {
  return conferences?.map((x) => {
    const rounds: StoreConferenceType['rounds'] | any = {
      [CompetitionSlotsEnum.A]: [],
      [CompetitionSlotsEnum.B]: [],
      [CompetitionSlotsEnum.C]: [],
      [CompetitionSlotsEnum.D]: [],
      [CompetitionSlotsEnum.E]: [],
      [CompetitionSlotsEnum.F]: [],
      [CompetitionSlotsEnum.G]: [],
      [CompetitionSlotsEnum.H]: [],
      [CompetitionSlotsEnum.I]: [],
      [CompetitionSlotsEnum.J]: [],
    };
    const conferenceMatches = matches?.filter(
      (y) => y.conference_id === x.conference_id,
    );

    conferenceMatches?.map((y) => {
      const competitionSlot = y.slot?.[0] as keyof typeof CompetitionSlotsEnum;
      if (competitionSlot) {
        rounds[competitionSlot] = [...rounds[competitionSlot], y];
      }
      return;
    });
    return { ...x, rounds };
  });
};

export const totalSelectedMatches = (
  matches: MatchType[],
  selections: Partial<MatchSelectionType>[],
) => {
  return selections.reduce((acc, x) => {
    return matches.find((y) => y.match_id === x.match_id) ? (acc += 1) : acc;
  }, 0);
};

export const calcBracketProgress = (
  matches: MatchType[],
  selections: Partial<MatchSelectionType>[],
  tiebreakerValue: MatchesInitialStateType['tiebreakerValue'],
) => {
  const totalMatches = matches?.length + 1; // +1 it's tiebreaker value
  const totalMatchesInSelections = totalSelectedMatches(matches, selections);
  const totalSelectedWithTiebreaker = tiebreakerValue
    ? totalMatchesInSelections + 1
    : totalMatchesInSelections;
  const percent = ((totalSelectedWithTiebreaker / totalMatches) * 100).toFixed(
    0,
  );
  // Ensure the value is within the range [0, 100]
  return Math.min(100, Math.max(0, Number(percent)));
};

export const filterMatchesByConferenceId = (
  conference_id: number,
  matches: MatchType[],
) => matches?.filter((x) => x.conference_id === conference_id);

export const isConferenceCompleted = ({
  conference_id,
  selections,
  matches,
}: {
  conference_id: number;
  selections: Partial<MatchSelectionType>[];
  matches: MatchType[];
}) => {
  const conferenceMatches = filterMatchesByConferenceId(conference_id, matches);
  // TODO: simplify to selection.conference_id === match.conference_id when BE will be ready
  const conferenceSelections = selections?.filter(
    (x) => conferenceMatches?.find((y) => y.match_id === x.match_id),
  );

  return !!(
    conferenceMatches?.length &&
    conferenceSelections?.length &&
    conferenceMatches?.length === conferenceSelections?.length
  );
};

export const nextUncompletedConference = ({
  conferences,
  matches,
  selections,
}: {
  conferences: StoreConferenceType[];
  matches: MatchType[];
  selections: Partial<MatchSelectionType>[];
}) =>
  conferences?.find(
    (x) =>
      !isConferenceCompleted({
        conference_id: x.conference_id as number,
        matches,
        selections,
      }),
  );

export const isBracketCompleted = ({
  conferences,
  matches,
  selections,
}: {
  conferences: StoreConferenceType[];
  matches: MatchType[];
  selections: Partial<MatchSelectionType>[];
}) =>
  conferences?.every((x) =>
    isConferenceCompleted({
      conference_id: x.conference_id as number,
      matches,
      selections,
    }),
  );

export const isAllConferencesExceptFinalCompleted = ({
  conferences,
  matches,
  selections,
}: {
  conferences: StoreConferenceType[];
  matches: MatchType[];
  selections: Partial<MatchSelectionType>[];
}) =>
  conferences
    ?.filter((x) => !x.rounds['A'].length)
    .every((x) =>
      isConferenceCompleted({
        conference_id: x.conference_id as number,
        matches,
        selections,
      }),
    );

export const SlotsArr = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i),
);

export const setTeamInNeededSlotNextMatch = ({
  foundMatch,
  winner_team_id,
  posOfCurrSlot,
}: {
  foundMatch: MatchType | undefined;
  winner_team_id: number | null;
  posOfCurrSlot: number;
}) => {
  const isHomeTeamClicked = winner_team_id === foundMatch?.home_team_id;
  if (foundMatch) {
    if (posOfCurrSlot % 2 === 0) {
      const {
        match_id,
        slot,
        match_feed_id,
        start_utc,
        away_team_id,
        away_team_rank,
        away_team_name_en,
        away_team_logo,
        round_name_en,
        ...rest
      } = foundMatch || {};
      return {
        ...rest,
        home_team_id: isHomeTeamClicked
          ? foundMatch?.home_team_id
          : foundMatch?.away_team_id,
        home_team_rank: isHomeTeamClicked
          ? foundMatch?.home_team_rank
          : foundMatch?.away_team_rank,
        home_team_name_en: isHomeTeamClicked
          ? foundMatch?.home_team_name_en
          : foundMatch?.away_team_name_en,
        home_team_logo: isHomeTeamClicked
          ? foundMatch?.home_team_logo
          : foundMatch?.away_team_logo,
      };
    } else {
      const {
        match_id,
        slot,
        match_feed_id,
        start_utc,
        home_team_id,
        home_team_rank,
        home_team_name_en,
        home_team_logo,
        round_name_en,
        ...rest
      } = foundMatch || {};
      return {
        ...rest,
        away_team_id: isHomeTeamClicked
          ? foundMatch?.home_team_id
          : foundMatch?.away_team_id,
        away_team_rank: isHomeTeamClicked
          ? foundMatch?.home_team_rank
          : foundMatch?.away_team_rank,
        away_team_name_en: isHomeTeamClicked
          ? foundMatch?.home_team_name_en
          : foundMatch?.away_team_name_en,
        away_team_logo: isHomeTeamClicked
          ? foundMatch?.home_team_logo
          : foundMatch?.away_team_logo,
      };
    }
  }
  return {};
};

export const transformRoundsGridIntoArray = (
  rounds: StoreConferenceType['rounds'],
) => Object.values(rounds).flat();

export const moveArrItemToCenterOfArr = (
  arr: MatchType[],
  item: MatchType | undefined,
) => {
  if (!item) return arr;
  const index = arr.findIndex((x) => x.conference_id === item.conference_id);

  if (index === -1) return arr;
  arr.splice(index, 1);
  const centerIndex = Math.floor(arr.length / 2);
  arr.splice(centerIndex, 0, item);

  return arr;
};

export const isFinalConfMatchAndNoSelection = (
  optionStatus: OptionStatusType,
  align: ConferenceAlignEnum,
) => optionStatus === 'none' && align === ConferenceAlignEnum.center;

export const isPrevRoundAllSelected = ({
  conferences,
  lastFilledSlot,
  selections,
  selectedConferenceId,
}: {
  conferences: StoreConferenceType[];
  lastFilledSlot: MatchesInitialStateType['lastFilledSlot'];
  selectedConferenceId: MatchesInitialStateType['selectedConferenceId'];
  selections: MatchesInitialStateType['selections'];
}) => {
  const foundConference = conferences?.find(
    (x) => x.conference_id === selectedConferenceId,
  );
  const foundPrevRoundSlot = lastFilledSlot?.slot
    ? Object.keys(foundConference?.rounds || [])?.find(
        (x) => x > lastFilledSlot?.slot?.[0],
      )
    : undefined;

  if (!foundConference || !foundPrevRoundSlot) return false;

  const neededRound =
    foundConference.rounds[foundPrevRoundSlot as CompetitionSlotsEnum];

  const roundMatchesInSelections = selections?.filter((x) =>
    neededRound.find((y) => y.match_id === x.match_id),
  );

  return neededRound.length === roundMatchesInSelections.length;
};

export const isTiebreakerButtonDisabled = (
  tiebreakerVal: string,
  isAllBracketCompleted: boolean,
) => !tiebreakerVal || !isAllBracketCompleted;

export const resetNeededMatchInMatchesByMatchId = (
  matches: MatchesInitialStateType['matches'],
  newMatch: MatchType,
) =>
  matches.map((match) =>
    match.match_id === newMatch?.match_id ? newMatch : match,
  );

export const setNewBodyForAwayTeamMatch = ({
  currentRoundMatch,
  isAwayTeam,
  prevRoundMatch,
}: {
  currentRoundMatch: MatchType;
  isAwayTeam: boolean;
  prevRoundMatch: MatchType;
}) => ({
  ...currentRoundMatch,
  away_team_name_en: isAwayTeam
    ? prevRoundMatch.away_team_name_en
    : prevRoundMatch.home_team_name_en,
  away_team_id: isAwayTeam
    ? prevRoundMatch.away_team_id
    : prevRoundMatch.home_team_id,
  away_team_rank: isAwayTeam
    ? prevRoundMatch.away_team_rank
    : prevRoundMatch.home_team_rank,
  away_team_logo: isAwayTeam
    ? prevRoundMatch.away_team_logo
    : prevRoundMatch.home_team_logo,
});

export const setNewBodyForHomeTeamMatch = ({
  currentRoundMatch,
  isAwayTeam,
  prevRoundMatch,
}: {
  currentRoundMatch: MatchType;
  isAwayTeam: boolean;
  prevRoundMatch: MatchType;
}) => ({
  ...currentRoundMatch,
  home_team_name_en: isAwayTeam
    ? prevRoundMatch.away_team_name_en
    : prevRoundMatch.home_team_name_en,
  home_team_id: isAwayTeam
    ? prevRoundMatch.away_team_id
    : prevRoundMatch.home_team_id,
  home_team_rank: isAwayTeam
    ? prevRoundMatch.away_team_rank
    : prevRoundMatch.home_team_rank,
  home_team_logo: isAwayTeam
    ? prevRoundMatch.away_team_logo
    : prevRoundMatch.home_team_logo,
});

export const nullifyHomeTeamMatch = (match: MatchType) => ({
  ...match,
  home_team_name_en: null,
  home_team_id: null,
  home_team_rank: null,
  home_team_logo: null,
});

export const nullifyAwayTeamMatch = (match: MatchType) => ({
  ...match,
  away_team_name_en: null,
  away_team_id: null,
  away_team_rank: null,
  away_team_logo: null,
});

export const isSelectionsHaveNotBeenChanged = (
  selections: MatchesInitialStateType['selections'],
  initialSelections: MatchesInitialStateType['initialSelections'],
) => {
  const notChangedSelections = selections.filter((s) =>
    initialSelections.some(
      (is) =>
        is.match_id === s.match_id && is.winner_team_id === s.winner_team_id,
    ),
  );
  return notChangedSelections.length === initialSelections.length;
};

export const foundFinalConference = (
  conferences: MatchesInitialStateType['conferences'],
) => conferences.find((x) => x.rounds[CompetitionSlotsEnum.A].length);

export const teamNameColorInMatch = (
  foundMatchInInitialMatches: MatchType | undefined,
  isTeamEqualsToInitMatchesTeam: boolean,
  isBracketEnabled: boolean,
) => {
  if (
    !isBracketEnabled &&
    isExist(foundMatchInInitialMatches?.winner_team_id)
  ) {
    return isTeamEqualsToInitMatchesTeam ? 'inherit' : 'rydelRed';
  }
  return 'inherit';
};

export const teamNameDecorationInMatch = (
  foundMatchInInitialMatches: MatchType | undefined,
  isTeamEqualsToInitMatchesTeam: boolean,
  isBracketEnabled: boolean,
) => {
  if (
    !isBracketEnabled &&
    isExist(foundMatchInInitialMatches?.winner_team_id)
  ) {
    return isTeamEqualsToInitMatchesTeam ? 'unset' : 'line-through';
  }
  return 'unset';
};

export const isRealTeamInMatchShown = (
  foundMatchInInitialMatches: MatchType | undefined,
  isTeamEqualsToInitMatchesTeam: boolean,
  isBracketEnabled: boolean,
) =>
  !isBracketEnabled &&
  !!foundMatchInInitialMatches &&
  isExist(foundMatchInInitialMatches?.winner_team_id) &&
  !isTeamEqualsToInitMatchesTeam;
