import { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  sizes: {
    md: {
      control: {
        width: '26px',
        height: '26px',
      },
      label: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '140%',
      },
    },
  },
  baseStyle: {
    label: {
      color: 'white',
    },
    control: {
      background: 'transparent',
      borderRadius: '4px',
      borderColor: 'white',

      _checked: {
        background: 'transparent',
        color: 'white',
        border: 'white',

        _hover: {
          background: 'transparent',
          color: 'white',
          border: 'white',
        },
      },
    },
  },
};

export default Checkbox;
