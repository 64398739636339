import React from 'react';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Text,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import Burger from '@/assets/icons/burger.svg';

import DiagramInteractionButtons from '@/components/DiagramInteractionButtons';

import { useAppNav } from '@/hooks/useAppNav.hook';
import { useAuthContext } from '@/hooks/useAuthContext.hook';
import { useJWT } from '@/hooks/useJWT.hook';
import { useSettings } from '@/hooks/useSettings.hook';

export const Header: React.FC = () => {
  const { isMobile } = useSettings();
  const { headerNav, logout } = useAppNav();
  const { onSignInOpen } = useAuthContext();

  const { jwt } = useJWT();

  return isMobile ? (
    <>
      <Box
        background='radial-gradient(149.86% 62.6% at 50.13% 0%, #2677EE -50%, #001A3F 100%)'
        pb={isMobile ? '16px' : 0}
        px='16px'
      >
        <Flex
          alignItems='center'
          gap='8px'
          justifyContent='flex-start'
          position='relative'
          py='8px'
          w='100%'
        >
          <Popover placement='bottom-start'>
            <PopoverTrigger>
              <IconButton
                aria-label='burger'
                h='auto'
                icon={<Image alt='' h='22px' src={Burger} w='22px' />}
                minW='auto'
                onClick={() => ({})}
                variant='unstyled'
                w='auto'
              />
            </PopoverTrigger>
            <PopoverContent
              bg='aolisBlue'
              border='none'
              borderRadius='12px'
              w='135px'
            >
              <PopoverBody as={Flex} flexDir='column' p='4px'>
                {headerNav.map((item) => {
                  const isSignInModalAvailable = !jwt && item.isPrivateRoute;

                  return (
                    <Box
                      align='center'
                      as={NavLink}
                      fontSize='14px'
                      fontWeight='500'
                      gap='8px'
                      key={item.label}
                      lineHeight='1.7'
                      onClick={
                        isSignInModalAvailable ? onSignInOpen : undefined
                      }
                      p='8px'
                      pl='12px'
                      // @ts-expect-error TODO
                      to={
                        !isSignInModalAvailable ? item.linkData.to : undefined
                      }
                      w='100%'
                    >
                      {item.label}
                    </Box>
                  );
                })}

                {jwt && (
                  <Button
                    _hover={{ textDecor: 'none' }}
                    color='inherit'
                    fontSize='14px'
                    fontWeight='500'
                    gap='3px'
                    h='100%'
                    justifyContent='center'
                    lineHeight='1.7'
                    onClick={logout.handle}
                    p='8px'
                    pl='12px'
                    variant='link'
                  >
                    <Box>{logout.label}</Box>
                    {logout.renderIcon()}
                  </Button>
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Text color='absoluteWhite' fontSize='20px' fontWeight='700'></Text>
        </Flex>

        {/*<Box*/}
        {/*  bgColor='talsBlue'*/}
        {/*  border='1px solid'*/}
        {/*  borderColor='jhaerosBlue'*/}
        {/*  borderRadius='4px'*/}
        {/*  color='14px'*/}
        {/*  fontWeight='600'*/}
        {/*  px='14px'*/}
        {/*  py='7px'*/}
        {/*  textAlign='center'*/}
        {/*>*/}
        {/*  ADVERT*/}
        {/*</Box>*/}

        {isMobile && <DiagramInteractionButtons variant='mobile' />}
      </Box>
    </>
  ) : (
    <>
      <Heading
        as='h1'
        color='absoluteWhite'
        fontSize='20px'
        fontWeight='700'
        py='20px'
        textAlign='center'
      ></Heading>
    </>
  );
};
