import React from 'react';

import { Box, Button, Flex, useTheme } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useAppNav } from '@/hooks/useAppNav.hook';
import { useAuthContext } from '@/hooks/useAuthContext.hook';
import { useJWT } from '@/hooks/useJWT.hook';

const Navigation: React.FC = () => {
  const theme = useTheme();
  const { headerNav, logout } = useAppNav();
  const { jwt } = useJWT();

  const { onSignInOpen } = useAuthContext();

  return (
    <>
      <Flex
        alignItems='center'
        background='radial-gradient(at top left, #2677EE -100%, #00204C 100%)'
        borderRadius='8px 8px 0 0'
        color='absoluteWhite'
        fontSize='14px'
        fontWeight='600'
        h='50px'
        justifyContent='center'
      >
        {headerNav.map((item) => {
          const isSignInModalAvailable = !jwt && item.isPrivateRoute;

          return (
            <Flex
              alignItems='center'
              as={NavLink}
              bgColor={item?.isSelected ? 'primaryBlue' : 'transparent'}
              gap='3px'
              h='100%'
              justifyContent='center'
              key={item.linkData.to}
              onClick={isSignInModalAvailable ? onSignInOpen : undefined}
              px='12px'
              to={!isSignInModalAvailable ? item.linkData.to : undefined}
            >
              {item.renderIcon({
                fill: theme.colors.absoluteWhite,
                fontSize: '14',
              })}
              <Box>{item.label}</Box>
            </Flex>
          );
        })}

        {jwt && (
          <Button
            _hover={{ textDecor: 'none' }}
            alignItems='center'
            color='inherit'
            fontSize='inherit'
            gap='3px'
            h='100%'
            justifyContent='center'
            onClick={logout.handle}
            px='12px'
            variant='link'
          >
            <Box>{logout.label}</Box>
            {logout.renderIcon()}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default Navigation;
