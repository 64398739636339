import React, { SVGProps } from 'react';

export const PrizeIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '12',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 13 13'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.22255 0.696289C3.0052 0.696289 0.399841 3.30747 0.399841 6.52482C0.399841 9.74217 3.0052 12.3534 6.22255 12.3534C9.44573 12.3534 12.0569 9.74217 12.0569 6.52482C12.0569 3.30747 9.44573 0.696289 6.22255 0.696289ZM8.69385 10.0219L6.22838 8.53567L3.76291 10.0219L4.4157 7.21842L2.24166 5.3358L5.1093 5.091L6.22838 2.44485L7.34745 5.08518L10.2151 5.32997L8.04105 7.21259L8.69385 10.0219Z'
        fill={fill}
      />
    </svg>
  );
};
