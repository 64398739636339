import React, { SVGProps } from 'react';

export const MinusRoundIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '15',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 15 15'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.35136 0.378418C3.54704 0.378418 0.459473 3.46599 0.459473 7.27031C0.459473 11.0746 3.54704 14.1622 7.35136 14.1622C11.1557 14.1622 14.2433 11.0746 14.2433 7.27031C14.2433 3.46599 11.1557 0.378418 7.35136 0.378418ZM10.7973 7.9595H3.90542V6.58112H10.7973V7.9595Z'
        fill={fill}
      />
    </svg>
  );
};
