import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  CreateSelectionsReqBody,
  GetCurrentUserLeaderboardPosition,
  GetLeaderboardParams,
  GetMatchesRes,
  GetSelectionsRes,
  Leaderboard,
  LoginReqBody,
  LoginResponse,
  UpdateSelectionsReqBody,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api.util';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    generateJWTToken: builder.mutation<LoginResponse, LoginReqBody>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    getLeaderboard: builder.query<Leaderboard, GetLeaderboardParams>({
      query: ({ offset, limit, pickemId }) => ({
        url: `/leaderboard/pickem/${pickemId}?offset=${offset}&limit=${limit}`,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response) => {
        // Merge previous response with current and return it
        const mergedLeaderboard = [...currentCache.leaderboard];
        for (const newItem of response.leaderboard) {
          if (
            !mergedLeaderboard.some((item) => item.user_id === newItem.user_id)
          ) {
            mergedLeaderboard.push(newItem);
          }
        }
        currentCache.leaderboard = mergedLeaderboard;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.pickemId !== previousArg?.pickemId ||
          currentArg?.offset !== previousArg?.offset
        );
      },
      providesTags: [ApiTagsEnum.GET_LEADERBOARD],
    }),
    getCurrentUserLeaderboardPosition: builder.query<
      GetCurrentUserLeaderboardPosition,
      { pickemId: number }
    >({
      query: ({ pickemId }) => ({
        url: `/pickem-leaderboard/${pickemId}/user`,
      }),
    }),
    getMatches: builder.query<GetMatchesRes, void>({
      query: () => '/matches',
      providesTags: [ApiTagsEnum.GET_MATCHES],
    }),
    getSelections: builder.query<GetSelectionsRes, void>({
      query: () => '/user-selections',
      providesTags: [ApiTagsEnum.GET_SELECTIONS],
    }),
    // TODO: add type
    createSelections: builder.mutation<any, CreateSelectionsReqBody>({
      query: (body) => ({
        url: '/user-selections',
        method: 'POST',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.GET_SELECTIONS],
    }),
    // TODO: add type
    updateSelections: builder.mutation<any, UpdateSelectionsReqBody>({
      query: ({ id, ...body }) => ({
        url: `/user-selections/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.GET_SELECTIONS],
    }),

    // getUserPickemLeaderboardPosition: builder.query<
    //   GetUserPickemLeaderboardPosition,
    //   void
    // >({
    //   query: () => ({
    //     url: '/tournament/1/leaderboard/user-results',
    //     headers: {
    //       Authorization:
    //         'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozLCJpYXQiOjE3MDQzNTIyODUsImV4cCI6MTcwNTIxNjI4NX0.V9H95UrhoXEKLeB5zN0D7PPkUuvtuHVNsJ_lI-vsbCc', // !jwt for test
    //     },
    //   }),
    //   providesTags: [ApiTagsEnum.GET_USER_PICKEM_LEADERBOARD_POSITION],
    // }),
  }),
});

export const {
  useGenerateJWTTokenMutation,
  useLazyGetLeaderboardQuery,
  useGetCurrentUserLeaderboardPositionQuery,
  useGetMatchesQuery,

  useGetSelectionsQuery,
  useUpdateSelectionsMutation,
  useCreateSelectionsMutation,
} = api;
