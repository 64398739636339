import { ComponentStyleConfig } from '@chakra-ui/react';

import colors from '@/theme/foundations/colors/index';

const defaultButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  textAlign: 'center',
  fontWeight: '500',
  lineHeight: '100%',
  color: 'absoluteWhite',
  border: 'none',
  cursor: 'pointer',
  userSelect: 'none',
  outline: 'none',
  boxShadow: 'none',
  textDecoration: 'none',
  gap: '5px',
  height: 'auto',
  transition: 'all .2s linear',
  '.chakra-button__icon': {
    marginLeft: '0',
  },
};

const buttonsWithShadowAnimation = {
  transition: 'box-shadow 0.3s, transform 0.3s',
  _focus: {
    boxShadow: 'buttonShadow',
    transform: 'translateY(0)',
  },
  _active: {
    boxShadow: 'none',
    transform: 'translateY(4px)',
  },
  _disabled: {
    outline: 'none',
    boxShadow: 'buttonShadow',
    _hover: {
      boxShadow: 'buttonShadow',
      transform: 'translateY(0)',
    },
  },
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    outline: 'none',
    whiteSpace: 'normal',
    _hover: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '8px',
      px: '10px',
      py: '8px',
      borderRadius: '4px',
    },
    md: {
      fontSize: '10px',
      px: '12px',
      py: '10px',
      borderRadius: '4px',
    },
    lg: {
      fontSize: '16px',
      px: '20px',
      py: '12px',
      borderRadius: '8px',
    },
  },
  variants: {
    white: {
      ...defaultButtonStyles,
      background: 'white',
      color: colors.mainBlue,
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: 'gray.200',
        _disabled: {
          background: 'gray.500',
          outline: 'none',
        },
      },
    },
    primary: {
      ...defaultButtonStyles,
      background: 'brekYellow',
      color: 'absoluteWhite',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: 'brekYellow',
        color: 'absoluteWhite',
        _disabled: {
          background: 'brekYellow',
          color: 'absoluteWhite',
          outline: 'none',
        },
      },
    },
    secondary: {
      ...defaultButtonStyles,
      background: 'transparent',
      border: '1px solid',
      borderColor: 'bladeGrey',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: 'transparent',
        _disabled: {
          background: 'transparent',
          outline: 'none',
        },
      },
    },
    ghost: {
      ...defaultButtonStyles,
      bgColor: 'gemotsGrey',
      color: 'absoluteBlack',
      _hover: {
        outline: 'none',
        boxShadow: 'none',
        bgColor: 'aolisBlue',
        color: 'absoluteBlack',
        textDecoration: 'none',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
        bgColor: 'gemotsGrey',
        color: 'absoluteBlack',
      },
      _active: {
        outline: 'none',
        boxShadow: 'none',
        bgColor: 'gemotsGrey',
        color: 'absoluteBlack',
      },
    },
    outlined: {
      ...defaultButtonStyles,
      border: '1px solid',
      borderColor: 'jhaerosBlue',
      bgColor: 'ilitranBlue',

      _hover: {
        background: 'button.primary.bg',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'white',
  },
};

export default Button;
