import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { LEADERBOARD_LIMIT, SportEnums } from '../config';
import { TenantEnum } from '../enums/tenant.enum';
import { createLeaderboardKey } from '../helpers';
import { LeaderboardInfo, UserType } from '../types';

type LeaderboardContextType = {
  pointsOrMoney: 'points' | 'money';
  setPointsOrMoney: Dispatch<React.SetStateAction<'points' | 'money'>>;
  sport: SportEnums;
  setSport: Dispatch<React.SetStateAction<SportEnums>>;
  tenant: TenantEnum;
  setTenant: Dispatch<React.SetStateAction<TenantEnum>>;
  leagueId: number;
  setLeagueId: Dispatch<React.SetStateAction<number>>;
  tournamentId: number | null;
  setTournamentId: Dispatch<React.SetStateAction<number | null>>;
  pickemLeaderboard: InitialLeaderboardType;
  setPickemLeaderboard: (payload: {
    id: number;
    leaderboardData: LeaderboardInfo;
    offset: number;
  }) => void;
  leagueLeaderboard: InitialLeaderboardType;
  setLeagueLeaderboard: (payload: {
    key: string;
    leaderboardData: LeaderboardInfo;
    offset: number;
  }) => void;
  currentLeaderboard: LeaderboardInfo | null;

  selectedUser: UserType | null;
  setSelectedUser: Dispatch<React.SetStateAction<UserType | null>>;
};

const LeaderboardContext = createContext<LeaderboardContextType>(
  {} as LeaderboardContextType,
);

const LeaderboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pointsOrMoney, setPointsOrMoney] = useState<'points' | 'money'>(
    'points',
  );
  const [sport, setSport] = useState<SportEnums>(SportEnums.ALL_SPORTS);
  const [tenant, setTenant] = useState<TenantEnum>(TenantEnum.null);
  const [leagueId, setLeagueId] = useState<number>(1);
  const [tournamentId, setTournamentId] = useState<number | null>(1);

  const [pickemLeaderboard, setPickemLeaderboard] = useState({});
  const [leagueLeaderboard, setLeagueLeaderboard] =
    useState<InitialLeaderboardType>({});

  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);

  // TODO Try to get data from redux directly
  // const state = useAppSelector((state) => state.mainApi.queries);
  // const key = `getTournamentLeaderboard({"league_id":${leagueId},"tournament_id":${tournamentId},"tenant":${tenant}})`;
  // const currentLeaderboardNEW = state[key]?.data as LeaderboardInfo;

  const setPickemLeaderboardOffset = (payload: {
    id: number;
    leaderboardData: LeaderboardInfo;
    offset: number;
  }) => {
    setPickemLeaderboard((prevState) => {
      return {
        ...prevState,
        [payload.id]: {
          ...payload.leaderboardData,
          offset: (payload?.offset || 0) + LEADERBOARD_LIMIT, // Increase offset on limit
        },
      };
    });
  };

  const setLeagueLeaderboardDataWithOffset = (payload: {
    key: string;
    leaderboardData: LeaderboardInfo;
    offset: number;
  }) => {
    setLeagueLeaderboard((prevState) => {
      return {
        ...prevState,
        [payload.key]: {
          ...payload?.leaderboardData,
          offset: (payload?.offset || 0) + LEADERBOARD_LIMIT, // Increase offset on limit
        },
      };
    });
  };

  const currentLeaderboard = useMemo(
    () =>
      leagueLeaderboard[
        createLeaderboardKey(leagueId, tournamentId as number, tenant)
      ] || null,
    [leagueLeaderboard],
  );

  return (
    <LeaderboardContext.Provider
      value={{
        pointsOrMoney,
        setPointsOrMoney,
        sport,
        setSport,
        tenant,
        setTenant,
        leagueId,
        setLeagueId,
        tournamentId,
        setTournamentId,
        pickemLeaderboard,
        setPickemLeaderboard: setPickemLeaderboardOffset,
        leagueLeaderboard,
        setLeagueLeaderboard: setLeagueLeaderboardDataWithOffset,
        currentLeaderboard,

        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};

export { LeaderboardProvider };
export const useLeaderboardContext = () => useContext(LeaderboardContext);

interface LeaderboardStoreItem extends LeaderboardInfo {
  offset: number;
}

type InitialLeaderboardType = {
  [key: number | string]: LeaderboardStoreItem | null;
};
