import React, { useCallback } from 'react';

import { RootState } from '@/store';

import { useAppDispatch, useAppSelector } from '@/store/hooks/index';
import {
  CLEAR_BRACKET,
  FILL_MATCHES_BY_PREDEFINED_SELECTIONS,
  MatchesInitialStateType,
  RANDOMIZE_BRACKET,
  SET_BRACKET_SETTING,
  RANDOMIZE_CURRENT_CONFERENCE,
  SET_CONFERENCES,
  SET_INITIAL_SELECTIONS,
  SET_IS_BRACKET_ENABLED,
  SET_MATCHES,
  SET_SELECTED_CONFERENCE_ID,
  SET_SELECTED_ROUND_ID,
  SET_SELECTION,
  SET_TAB_INDEX,
  SET_TIEBREAKER_VALUE,
  SET_USER_SELECTIONS_INFO,
  SetSelectionType,
} from '@/store/slices/matches.slice';

export const useMatchesStore = () => {
  const dispatch = useAppDispatch();
  const {
    selectedConferenceId,
    selectedRoundId,
    initialSelections,
    selections,
    conferences,
    matches,
    tabIndex,
    lastFilledSlot,
    userSelectionsInfo,
    initialConferences,
    tiebreakerValue,
    initialMatches,
    bracketSetting,
    isBracketEnabled,
  } = useAppSelector((state: RootState) => state.matches);

  const setSelectedConferenceId = useCallback(
    (id: MatchesInitialStateType['selectedConferenceId']) =>
      dispatch(SET_SELECTED_CONFERENCE_ID(id)),
    [dispatch],
  );
  const setSelectedRoundId = useCallback(
    (id: MatchesInitialStateType['selectedRoundId']) =>
      dispatch(SET_SELECTED_ROUND_ID(id)),
    [dispatch],
  );
  const setInitialSelections = useCallback(
    (data: MatchesInitialStateType['initialSelections']) =>
      dispatch(SET_INITIAL_SELECTIONS(data)),
    [dispatch],
  );
  const setSelection = useCallback(
    (data: SetSelectionType) => dispatch(SET_SELECTION(data)),
    [dispatch],
  );
  const setConferences = useCallback(
    (data: MatchesInitialStateType['conferences']) =>
      dispatch(SET_CONFERENCES(data)),
    [dispatch],
  );
  const setMatches = useCallback(
    (data: MatchesInitialStateType['matches']) => dispatch(SET_MATCHES(data)),
    [dispatch],
  );
  const setTabIndex = useCallback(
    (data: MatchesInitialStateType['tabIndex']) =>
      dispatch(SET_TAB_INDEX(data)),
    [dispatch],
  );
  const setUserSelectionsInfo = useCallback(
    (data: MatchesInitialStateType['userSelectionsInfo']) =>
      dispatch(SET_USER_SELECTIONS_INFO(data)),
    [dispatch],
  );
  const fillMatchesByPredefinedSelections = useCallback(
    () => dispatch(FILL_MATCHES_BY_PREDEFINED_SELECTIONS()),
    [dispatch],
  );
  const clearBracket = useCallback(() => dispatch(CLEAR_BRACKET()), [dispatch]);
  const randomizeBracket = useCallback(
    () => dispatch(RANDOMIZE_BRACKET()),
    [dispatch],
  );
  const setTiebreakerValue = useCallback(
    (data: MatchesInitialStateType['tiebreakerValue']) =>
      dispatch(SET_TIEBREAKER_VALUE(data)),
    [dispatch],
  );
  const setBracketSetting = useCallback(
    (data: MatchesInitialStateType['bracketSetting']) =>
      dispatch(SET_BRACKET_SETTING(data)),
    [dispatch],
  );
  const setIsBracketEnabled = useCallback(
    (data: MatchesInitialStateType['isBracketEnabled']) =>
      dispatch(SET_IS_BRACKET_ENABLED(data)),
    [dispatch],
  );
  const randomizeCurrentConference = useCallback(
    () => dispatch(RANDOMIZE_CURRENT_CONFERENCE()),
    [dispatch],
  );

  return {
    selectedRoundId,
    selectedConferenceId,
    initialSelections,
    selections,
    conferences,
    matches,
    tabIndex,
    lastFilledSlot,
    userSelectionsInfo,
    initialConferences,
    tiebreakerValue,
    initialMatches,
    bracketSetting,
    isBracketEnabled,

    setSelectedConferenceId,
    setSelectedRoundId,
    setInitialSelections,
    setSelection,
    setConferences,
    setMatches,
    setTabIndex,
    setUserSelectionsInfo,
    fillMatchesByPredefinedSelections,
    clearBracket,
    randomizeBracket,
    setTiebreakerValue,
    setBracketSetting,
    setIsBracketEnabled,
    randomizeCurrentConference,
  };
};
