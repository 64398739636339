const colors = {
  mainBlue: '#0A143C',
  primaryBlue: '#1A2345',
  secondaryBlue: '#273256',
  absoluteWhite: '#FFFFFF',
  primaryOrange: '#F58720',
  opacityWhite: 'rgba(255, 255, 255, 0.35)',

  ingrisBrown: 'rgba(245, 135, 32, 0.44)',

  primaryGrey: '#E8E8E8',
  imnerGrey: '#92A2B9',
  gemotsGrey: '#C5D0D4',
  bladeGrey: '#566789',
  dionisGrey: '#C8D0DC',
  broxGrey: '#EFF1F4',
  syrexGrey: '#C2D2D8',
  dulinGrey: '#CDD5DC',
  grahamGrey: '#BCBCBC',

  absoluteBlack: '#000000',

  cuksBlue: '#0A2540',
  talsBlue: '#0F172A',
  valdreBlue: '#091A34',
  oxfordBlue: '#001A3F',
  shinelBlue: '#0A3878',
  folmarBlue: '#0254D6',
  aolisBlue: '#003D66',
  siverilBlue: '#002B47',
  jhaerosBlue: '#0262F9',
  ilitranBlue: 'rgba(15, 23, 42, 0.2)',

  brekYellow: '#FDB61B',

  sontarGreen: '#00BF00',

  rydelRed: '#FD2306',
  vividRed: '#E44242',

  crinGradient:
    'radial-gradient(1678.7% 4104.44% at 11.84% -453.85%, #2677EE 0%, #00204C 100%)',
};

export default colors;
