import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Link,
  ModalBody,
  Flex,
  useTheme,
} from '@chakra-ui/react';

import { GAMEZONE_URL } from '@/utils/constants.util';
import { useSettings } from '@/hooks/useSettings.hook';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SignInModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  const { isMobile } = useSettings();

  const theme = useTheme();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent marginLeft='16px' marginRight='16px'>
        <ModalHeader>Log In Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Please log in or create an account to make your bracket picks.</p>
        </ModalBody>
        <ModalFooter>
          <Flex
            flexDirection={isMobile ? 'column' : 'row'}
            width='100%'
            justifyContent='center'
          >
            <Button
              as={Link}
              href={`${GAMEZONE_URL}/login`}
              color={theme.colors.valdreBlue}
              _hover={{
                color: theme.colors.valdreBlue,
                textDecoration: 'none',
              }}
              textDecoration='none'
              fontWeight='700'
              width={isMobile ? '100%' : '200px'}
              variant='primary'
              mr={!isMobile ? '16px' : undefined}
              mb={isMobile ? '16px' : undefined}
            >
              Log in
            </Button>
            <Button
              as={Link}
              href={`${GAMEZONE_URL}/registration`}
              color={theme.colors.brekYellow}
              borderColor={theme.colors.brekYellow}
              _hover={{
                color: theme.colors.valdreBlue,
                background: theme.colors.brekYellow,
                textDecoration: 'none',
              }}
              width={isMobile ? '100%' : '200px'}
              variant='outline'
            >
              Sign up
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
