import React, { SVGProps } from 'react';

export const HelpIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '12',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 13 13'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.2805 0.695801C3.06315 0.695801 0.451965 3.30698 0.451965 6.52434C0.451965 9.74169 3.06315 12.3529 6.2805 12.3529C9.49785 12.3529 12.109 9.74169 12.109 6.52434C12.109 3.30698 9.49785 0.695801 6.2805 0.695801ZM6.86335 10.6043H5.69765V9.4386H6.86335V10.6043ZM8.06986 6.0872L7.54529 6.62342C7.12564 7.0489 6.86335 7.39862 6.86335 8.2729H5.69765V7.98147C5.69765 7.34033 5.95993 6.75748 6.37958 6.33199L7.10232 5.5976C7.31798 5.38777 7.44621 5.09634 7.44621 4.77577C7.44621 4.13464 6.92164 3.61007 6.2805 3.61007C5.63936 3.61007 5.11479 4.13464 5.11479 4.77577H3.94909C3.94909 3.48767 4.99239 2.44436 6.2805 2.44436C7.56861 2.44436 8.61191 3.48767 8.61191 4.77577C8.61191 5.28869 8.40209 5.75497 8.06986 6.0872Z'
        fill={fill}
      />
    </svg>
  );
};
