import React, { Suspense } from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { AppRoutes } from '@/routes';

import Layout from '@/components/Layout';

import { PageLoader } from '@/elements/PageLoader';

function App() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route element={<Layout />}>
          {AppRoutes.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
