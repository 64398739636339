import React, { SVGProps } from 'react';

export const BracketIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '12',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 10 13'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.31543 12.6447V10.8961H2.50114L2.50111 9.1476H0.31543V7.39904H4.24969V9.1476H5.99824V3.90192H4.24969V5.65047H0.31543V3.90192H2.50114L2.50111 2.15334L0.31543 2.15336V0.404785H4.24969V2.15334L7.74679 2.15336V5.65047H9.9325V7.39904H7.74679V10.8961H4.24969V12.6447H0.31543Z'
        fill={fill}
      />
    </svg>
  );
};
