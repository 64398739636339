export enum ApiTagsEnum {
  GET_CURRENT_USER_LEAGUE_POSITION = 'GET_CURRENT_USER_LEAGUE_POSITION',
  GET_FRIENDS_LEAGUE_TOURNAMENT_ID = 'GET_FRIENDS_LEAGUE_TOURNAMENT_ID',
  GET_LEADERBOARD = 'GET_LEADERBOARD',
  GET_MATCHES = 'GET_MATCHES',
  GET_SELECTIONS = 'GET_SELECTIONS',
  GET_LEAGUE_BY_ID = 'GET_LEAGUE_BY_ID',
  GET_LEAGUE_LEADERBOARD = 'GET_LEAGUE_LEADERBOARD',
  GET_SEASON = 'GET_SEASON',

  GET_USER_LEAGUES = 'GET_USER_LEAGUES',

  LEAGUE_LEADERBOARD = 'LEAGUE_LEADERBOARD',
  PICKEM = 'PICKEM',
  USER_SELECTION_BY_ID = 'USER_SELECTION_BY_ID',
  PICKEM_LEADERBOARD = 'PICKEM_LEADERBOARD',
  TOURNAMENTS_BY_LEAGUE_ID = 'TOURNAMENTS_BY_LEAGUE_ID',
}
