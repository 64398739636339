import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useMatchesStore } from '@/store/hooks/useMatchesStore.hook';

import { ModalProps } from '@/types/domain';

export const ClearBracketModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { clearBracket } = useMatchesStore();
  const onModalSubmit = () => {
    clearBracket();
    onClose();
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxW='330px'>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>This will clear the full bracket</ModalBody>

        <ModalFooter>
          <Button onClick={onModalSubmit} size='lg' variant='primary' w='100%'>
            Clear Full Bracket
          </Button>
          <Button onClick={onClose} size='lg' variant='secondary' w='100%'>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
