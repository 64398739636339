import React from 'react';

import { Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { Header } from '@/components/Layout/Header';
import Navigation from '@/components/Layout/Navigation';

import { useSettings } from '@/hooks/useSettings.hook';

const Layout: React.FC = () => {
  const { isMobile } = useSettings();
  return (
    <>
      <Header />

      <Container
        bgColor='valdreBlue'
        borderRadius={isMobile ? '0' : '8px'}
        boxShadow={isMobile ? 'none' : 'blueShadow'}
        maxW='900px'
        mb={isMobile ? '0' : '30px'}
        px='0'
      >
        {!isMobile && <Navigation />}
        <Outlet />
      </Container>
    </>
  );
};

export default Layout;
