const shadows = {
  buttonShadow: '0px 0px 0px #00000',
  cardShadow: '0px 3.4375px 34.375px 0px rgba(255, 255, 255, 0.10)',
  contestCardShadow: '0px 7.03443px 7.03443px rgba(0, 0, 0, 0.25)',
  formShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 25px',
  selectShadow: '3px 3px 10px 6px rgba(0, 0, 0, 0.06)',
  navbarShadow: '0px 4px 16px 0px rgba(255, 255, 255, 0.15)',

  blueShadow: '0px 1.963302731513977px 104.05504608154297px 0px #00AEF94D',
  tyrexShadow: '0px -1.963302731513977px 4.908257007598877px 0px #0000001A',
  elmonShadow: '0px -4px 10px 0px #0000001A',
};

export default shadows;
