import { SVGProps, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { OVERALL_LEADERBOARD_ID } from '@/core/env';
import { LeaguesRoutesEnum } from '@/modules/FriendsLeagues/routes/index';

import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.svg';
import { ReactComponent as MyLeaguesIcon } from '@/assets/icons/my-leagues.svg';

import { RoutesEnum } from '@/enums/routes.enum';

import { BracketIcon } from '@/icons/Bracket.icon';
import { HelpIcon } from '@/icons/Help.icon';
import { LeaderboardIcon } from '@/icons/Leaderboard.icon';
import { PrizeIcon } from '@/icons/Prize.icon';
import { ProfileIcon } from '@/icons/Profile.icon';

import { GAMEZONE_URL } from '@/utils/constants.util';

import { useJWT } from './useJWT.hook';

export const useAppNav = () => {
  const { pathname } = useLocation();
  const { removeJWT } = useJWT();

  const headerNav = useMemo(
    () => [
      {
        label: 'Bracket',
        icon: <BracketIcon />,
        renderIcon: (props: SVGProps<any>) => <BracketIcon {...props} />,
        isSelected: pathname === RoutesEnum.home,
        isPrivateRoute: false,
        linkData: {
          to: RoutesEnum.home,
        },
      },
      {
        label: 'Leaderboard',
        icon: <LeaderboardIcon />,
        renderIcon: (props: SVGProps<any>) => <LeaderboardIcon {...props} />,
        isSelected: pathname === `/leaderboard/${OVERALL_LEADERBOARD_ID}`,
        isPrivateRoute: true,
        linkData: {
          to: `/leaderboard/${OVERALL_LEADERBOARD_ID}`,
        },
      },
      {
        label: 'My Leagues',
        icon: <MyLeaguesIcon />,
        renderIcon: (props: SVGProps<any>) => <MyLeaguesIcon {...props} />,
        isSelected: pathname === LeaguesRoutesEnum.leagues,
        isPrivateRoute: true,
        linkData: {
          to: LeaguesRoutesEnum.leagues,
        },
      },
      {
        label: 'Prizes',
        icon: <PrizeIcon />,
        renderIcon: (props: SVGProps<any>) => <PrizeIcon {...props} />,
        isSelected: pathname === RoutesEnum.prizes,
        isPrivateRoute: false,
        linkData: {
          to: RoutesEnum.prizes,
        },
      },
      {
        label: 'Help',
        icon: <HelpIcon />,
        renderIcon: (props: SVGProps<any>) => <HelpIcon {...props} />,
        isSelected: pathname === RoutesEnum.help,
        isPrivateRoute: false,
        linkData: {
          to: RoutesEnum.help,
        },
      },
      {
        label: 'Profile',
        icon: <ProfileIcon />,
        isPrivateRoute: true,
        renderIcon: (props: SVGProps<any>) => <ProfileIcon {...props} />,
        isSelected: pathname === RoutesEnum.profile,
        linkData: {
          to: `${GAMEZONE_URL}/profile`,
        },
      },
    ],
    [pathname],
  );

  const logout = useMemo(
    () => ({
      label: 'Logout',
      icon: <LogoutIcon />,
      renderIcon: (props?: SVGProps<any>) => <LogoutIcon {...props} />,
      handle: () => {
        removeJWT();
        window.location.href = `${GAMEZONE_URL}/registration`;
      },
    }),
    [removeJWT],
  );

  return { headerNav, logout };
};
