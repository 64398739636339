import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { ENABLED_LANGUAGES } from '@/core/env';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { getStorageItem } from '@/utils/localStorage.util';

import en from './locales/en.json';
import es from './locales/es.json';
import esp from './locales/esp.json';
import fr from './locales/fr.json';
import lv from './locales/lv.json';
import ru from './locales/ru.json';

export const DEFAULT_LANGUAGE = en;
export const enabledLanguages = ENABLED_LANGUAGES?.split(',') || ['en'];
export const resources = {
  en: { translation: en },
  fr: { translation: fr },
  es: { translation: es },
  esp: { translation: esp },
  lt: { translation: lv },
  rus: { translation: ru },
  sl: { translation: en }, // Slovak translations isn't exist yet
};
export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };
export const tKeys = Object.fromEntries(
  Object.keys(en).map((key) => [key, key]),
) as tKeysType;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: enabledLanguages,
    lng: getStorageItem<string>(StorageKeysEnum.i18nextLng) || 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
