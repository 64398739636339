import React, { SVGProps } from 'react';

export const ProfileIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '12',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 13 13'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.33269 0.695801C3.11533 0.695801 0.50415 3.30698 0.50415 6.52434C0.50415 9.74169 3.11533 12.3529 6.33269 12.3529C9.55004 12.3529 12.1612 9.74169 12.1612 6.52434C12.1612 3.30698 9.55004 0.695801 6.33269 0.695801ZM6.33269 2.44436C7.30022 2.44436 8.08125 3.22538 8.08125 4.19292C8.08125 5.16046 7.30022 5.94148 6.33269 5.94148C5.36515 5.94148 4.58412 5.16046 4.58412 4.19292C4.58412 3.22538 5.36515 2.44436 6.33269 2.44436ZM6.33269 10.7209C4.87555 10.7209 3.58745 9.97483 2.83556 8.84409C2.85305 7.68421 5.16698 7.0489 6.33269 7.0489C7.49256 7.0489 9.81232 7.68421 9.82981 8.84409C9.07793 9.97483 7.78982 10.7209 6.33269 10.7209Z'
        fill={fill}
      />
    </svg>
  );
};
