import { useMediaQuery } from '@chakra-ui/react';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from './useLocalStorage.hook';

export const useSettings = () => {
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);

  const [isDesktop, isMobile, isTablet] = useMediaQuery(
    [
      MediaQueriesEnum.isDesktop,
      MediaQueriesEnum.isMobile,
      MediaQueriesEnum.isTablet,
    ],
    {
      ssr: false,
    },
  );

  return { isDesktop, isMobile, isTablet, lang };
};
