import React from 'react';

import { Button, Flex, Grid, useDisclosure } from '@chakra-ui/react';

import { ClearBracketModal } from '@/components/Modals/ClearBracketModal';
import { RandomPicksModal } from '@/components/Modals/RandomPicksModal';

import { useAuthContext } from '@/hooks/useAuthContext.hook';
import { useJWT } from '@/hooks/useJWT.hook';

import { DominosIcon } from '@/icons/Dominos.icon';
import { MinusRoundIcon } from '@/icons/MinusRound.icon';
import { PrizeIcon } from '@/icons/Prize.icon';

import { useMatchesStore } from '@/store/hooks/useMatchesStore.hook';

interface DiagramInteractionButtonsProps {
  variant: 'desktop' | 'mobile';
}
const DiagramInteractionButtons: React.FC<DiagramInteractionButtonsProps> = ({
  variant,
}) => {
  const {
    isOpen: isClearOpen,
    onOpen: onClearOpen,
    onClose: onClearClose,
  } = useDisclosure();
  const {
    isOpen: isRandomOpen,
    onOpen: onRandomOpen,
    onClose: onRandomClose,
  } = useDisclosure();
  const { jwt } = useJWT();
  const { onSignInOpen } = useAuthContext();
  const { initialSelections, isBracketEnabled } = useMatchesStore();

  if (initialSelections?.length || !isBracketEnabled) return null;
  return (
    <>
      {variant === 'desktop' ? (
        <Flex
          alignItems='center'
          gap='8px'
          justifyContent='flex-end'
          p='16px'
          w='100%'
        >
          <Button
            onClick={jwt ? onRandomOpen : onSignInOpen}
            rightIcon={<DominosIcon fill='currentColor' />}
            size='sm'
            variant='ghost'
          >
            Random
          </Button>
          {/*<Button*/}
          {/*  rightIcon={<PrizeIcon fill='currentColor' fontSize='15' />}*/}
          {/*  size='sm'*/}
          {/*  variant='ghost'*/}
          {/*>*/}
          {/*  Favs*/}
          {/*</Button>*/}
          <Button
            onClick={jwt ? onClearOpen : onSignInOpen}
            rightIcon={<MinusRoundIcon fill='currentColor' />}
            size='sm'
            variant='ghost'
          >
            Clear
          </Button>
        </Flex>
      ) : (
        <Grid
          alignItems='center'
          gap='8px'
          py='8px'
          templateColumns='repeat(2, 1fr)'
        >
          <Button
            onClick={jwt ? onRandomOpen : onSignInOpen}
            rightIcon={<DominosIcon fill='currentColor' />}
            size='md'
            variant='outlined'
          >
            Random
          </Button>
          {/*<Button*/}
          {/*  rightIcon={<PrizeIcon fill='currentColor' fontSize='15' />}*/}
          {/*  size='md'*/}
          {/*  variant='outlined'*/}
          {/*>*/}
          {/*  Favs*/}
          {/*</Button>*/}
          <Button
            onClick={jwt ? onClearOpen : onSignInOpen}
            rightIcon={<MinusRoundIcon fill='currentColor' />}
            size='md'
            variant='outlined'
          >
            Clear
          </Button>
        </Grid>
      )}

      <ClearBracketModal isOpen={isClearOpen} onClose={onClearClose} />
      <RandomPicksModal isOpen={isRandomOpen} onClose={onRandomClose} />
    </>
  );
};

export default DiagramInteractionButtons;
