export const scrollToElement = (elementId: string, containerId: string) => {
  const container = document.getElementById(containerId);
  const element = document.getElementById(elementId);
  if (container && element) {
    // Calculate scroll position
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    const scrollLeft =
      elementRect.left - containerRect.left + container.scrollLeft;
    // Scroll to the position
    container.scrollTo({
      left: scrollLeft,
      behavior: 'smooth', // Optional: Use smooth scrolling
    });
  }
};

export const isExist = (val: any) => val !== undefined && val !== null;
