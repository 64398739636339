import React, { SVGProps } from 'react';

export const LeaderboardIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '12',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 14 15'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3835 1.87074H11.3132C11.3132 1.53502 11.3692 1.14334 11.3692 0.807617H2.92012C2.92012 1.19929 2.92012 1.53502 2.97607 1.87074H0.961731C0.961731 5.22798 2.86416 7.91377 5.21423 8.19354C5.38209 8.41735 5.54995 8.52926 5.71782 8.69712L5.54995 9.53643H6.38926V11.7186H5.494V12.5579H4.71064V14.2366H9.80246V12.5579H8.96315V11.7186H8.12384V9.59239H8.96315L8.9072 8.75308C8.9072 8.75308 9.24292 8.41735 9.41078 8.24949C11.593 7.91377 13.3835 5.17202 13.3835 1.87074ZM11.3692 2.766H12.5442C12.3204 4.78035 11.3692 6.45896 10.0822 7.13041C10.6418 5.95538 11.1454 4.38867 11.3692 2.766ZM1.80104 2.766H3.08798C3.3118 4.55653 3.75943 6.01133 4.37492 7.13041C2.97607 6.51492 2.02486 4.8363 1.80104 2.766Z'
        fill={fill}
      />
    </svg>
  );
};
