import { lazy, ReactNode } from 'react';

const LeaguesPage = lazy(() => import('./pages/Leagues.page'));
const JoinPage = lazy(() => import('./pages/Join.page'));
const CreatePage = lazy(() => import('./pages/Create.page'));
const LeaguesLeaderboard = lazy(
  () => import('./pages/LeaguesLeaderboard.page'),
);
const ShareCodePage = lazy(() => import('./pages/ShareCode.page'));

export enum LeaguesRoutesEnum {
  leagues = '/leagues',
  leaguesJoin = '/leagues/join',
  leaguesJoinSuccess = '/leagues/join/success',
  leaguesCreate = '/leagues/create',
  leaguesSpecific = '/leagues/:league_id',
  leaguesShareCode = '/leagues/share/:code',
}

interface IRoutes {
  path: LeaguesRoutesEnum;
  element: ReactNode;
  children?: IRoutes[];
}

export const FRIENDS_ROUTES: IRoutes[] = [
  { path: LeaguesRoutesEnum.leagues, element: <LeaguesPage /> },
  { path: LeaguesRoutesEnum.leaguesJoin, element: <JoinPage /> },
  { path: LeaguesRoutesEnum.leaguesJoinSuccess, element: <JoinPage /> },
  { path: LeaguesRoutesEnum.leaguesCreate, element: <CreatePage /> },
  { path: LeaguesRoutesEnum.leaguesSpecific, element: <LeaguesLeaderboard /> },
  { path: LeaguesRoutesEnum.leaguesShareCode, element: <ShareCodePage /> },
];
