import React, { SVGProps } from 'react';

export const DominosIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '15',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 15 15'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6401 0.270508H1.39009C0.906761 0.270508 0.513428 0.663841 0.513428 1.14384V13.3938C0.513428 13.8772 0.906761 14.2705 1.39009 14.2705H13.6401C14.1201 14.2705 14.5134 13.8772 14.5134 13.3938V1.14384C14.5134 0.663841 14.1201 0.270508 13.6401 0.270508ZM4.42676 12.2605C3.90343 12.7872 3.05009 12.7872 2.52343 12.2605C1.99676 11.7338 1.99676 10.8805 2.52343 10.3572C3.05009 9.83051 3.90343 9.83051 4.42676 10.3572C4.95343 10.8805 4.95343 11.7338 4.42676 12.2605ZM4.42676 4.15717C3.90343 4.68384 3.05009 4.68384 2.52343 4.15717C1.99676 3.63051 1.99676 2.77717 2.52343 2.25384C3.05009 1.72717 3.90343 1.72717 4.42676 2.25384C4.95343 2.77717 4.95343 3.63051 4.42676 4.15717ZM8.46676 8.22051C7.94009 8.74718 7.08676 8.74718 6.56343 8.22051C6.03676 7.69718 6.03676 6.84384 6.56343 6.31717C7.08676 5.79051 7.94009 5.79051 8.46676 6.31717C8.99343 6.84384 8.99343 7.69718 8.46676 8.22051ZM12.5301 12.2605C12.0068 12.7872 11.1534 12.7872 10.6268 12.2605C10.1001 11.7338 10.1001 10.8805 10.6268 10.3572C11.1534 9.83051 12.0068 9.83051 12.5301 10.3572C13.0568 10.8805 13.0568 11.7338 12.5301 12.2605ZM12.5301 4.15717C12.0068 4.68384 11.1534 4.68384 10.6268 4.15717C10.1001 3.63051 10.1001 2.77717 10.6268 2.25384C11.1534 1.72717 12.0068 1.72717 12.5301 2.25384C13.0568 2.77717 13.0568 3.63051 12.5301 4.15717Z'
        fill={fill}
      />
    </svg>
  );
};
